import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface GthGoogleMapMarkerItem {
  options: google.maps.marker.AdvancedMarkerElementOptions;
  markerClick?: any;
  item?: any;
  markerDrag?: any;
}

@Component({
  selector: 'gth-google-map-marker',
  template: '',
})
export class GthGoogleMapMarkerComponent {
  @Input()
  latitude = 0;

  @Input()
  longitude = 0;

  @Input()
  markerDraggable = false;

  @Input()
  item?: any;

  @Output()
  markerDrag = new EventEmitter<google.maps.MapMouseEvent>();

  @Output()
  markerClick = new EventEmitter<google.maps.MapMouseEvent>();
}
